import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BillingAccountWithMetadata, BillingCreditBundleWithMetadata, BillingService } from '../billing.service';
import { switchMap, takeUntil } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  BillingBundleStatus, 
  BillingCreditUsage,
  BillingProductPackageType,
  Roles,
  UpdateBillingAccount,
  UpdateBillingAccountPermissionsInput
} from '@connect-our-kids/connect-our-kids-lib/generated/graphql';
import { TeamUserService, TeamWithMetadata } from '../services/team-user.service';
import { combineLatest, Subject } from 'rxjs';
import { AuthService } from '../auth.service';
import { sortBillingAccountBundlesByExpirationDate } from '../util/billing-account.util';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

interface UserWithPermissions {
  id: number;
  fullName: string;
  canUseAccount: boolean;
  canManageBundles: boolean;
  canManageAccount: boolean;
}

interface DateRange {
  from: Date;
  to: Date;
}

@Component({
  selector: 'billing-details',
  templateUrl: './billing-details.component.html',
  styleUrls: ['./billing-details.component.scss'],
})
export class BillingDetailsComponent implements OnInit, OnDestroy {

  public readonly BillingProductPackageType = BillingProductPackageType;
  public readonly BillingBundleStatus = BillingBundleStatus;
  public readonly CREDIT_BUNDLES_TABLE_COLUMNS = ['index', 'purchaseDate', 'expirationDate', 'creditsCount', 'status', 'active'];
  public readonly CREDIT_USAGES_TABLE_COLUMNS: string[] = ['index', 'creditsUsed', 'date'];

  private readonly ON_DESTROY = new Subject<void>();

  @Input()
  public readonly isPersonalAccount: boolean;

  @Input()
  public readonly selectedBillingAccount: BillingAccountWithMetadata;

  @ViewChild('billingAccountSettingsModal', {static: true})
  public billingAccountSettingsModal: ElementRef;

  @ViewChild('deleteBillingAccountConfirmationModal', {static: true})
  public deleteBillingAccountConfirmationModal: ElementRef;

  @ViewChild('addCreditsModal', {static: true})
  public addCreditsModal: ElementRef;

  @ViewChild('billingPermissionModal', {static: true})
  public billingPermissionModal: ElementRef;

  @ViewChild('bundlesPaginator')
  private set bundlesPaginator(paginator: MatPaginator) {
    this.bundles.paginator = paginator;
  }

  @ViewChild('creditUsagePaginator')
  private set creditUsagePaginator(paginator: MatPaginator) {
    this.creditUsages.paginator = paginator;
  }

  public loadingBillingAccountInfo = false;
  public loadingCreditUsages = false;
  public activatingBundle = false;
  public savingBillingAccount = false;
  public savingPermissions = false;
  public deletingBillingAccount = false;
  public isCreatingCustomerSession = false;

  public billingAccountForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    description: new UntypedFormControl('', [Validators.required]),
    emailAddress: new UntypedFormControl('', [Validators.required, Validators.email]),
    isDefault: new UntypedFormControl(false, []),
  });

  public bundles = new MatTableDataSource<BillingCreditBundleWithMetadata>([]);
  public creditUsages = new MatTableDataSource<BillingCreditUsage>([]);

  public activeBillingAccount: BillingAccountWithMetadata;
  public activeBundle: BillingCreditBundleWithMetadata;

  public selectedBundle: BillingProductPackageType;

  public redirectingToStripe = false;

  public userWithPermissions: UserWithPermissions;
  public usersWithPermissions: UserWithPermissions[] = [];

  public selectedTeam: TeamWithMetadata;

  public creditUsagesFromDate: NgbDate;
  public creditUsagesToDate: NgbDate;
	public hoveredDate: NgbDate | null = null;


  constructor(
    private modal: NgbModal,
    private billingService: BillingService,
    private teamUserService: TeamUserService,
    private authService: AuthService,
    private calendar: NgbCalendar,
    private dateFormatter: NgbDateParserFormatter
  ) {
  }

  public get isUserManager(): boolean {
    if (this.isPersonalAccount) {
      return true;
    }

    return this.selectedTeam.isUserManager;
  }

  public get creditUsagesRange(): string {
    return this.dateFormatter.format(this.creditUsagesFromDate) + ' - ' + this.dateFormatter.format(this.creditUsagesToDate);
  }

  public ngOnDestroy(): void {
    this.ON_DESTROY.next();
    this.ON_DESTROY.complete();
  }

  public ngOnInit(): void {
    this.setDefaultDateRangeForUsages();

    this.loadingBillingAccountInfo = true;
    this.loadingCreditUsages = true;

    if (this.isPersonalAccount) {
      this.loadPersonalBillingAccountData();
      return;
    }

    this.loadBillingAccountData();
  }

  public loadBillingAccountData(): void {
    const { from, to } = this.getDateRangesForUsages();

    combineLatest([
        this.teamUserService.getUsersOfTeam(this.selectedBillingAccount.teamId),
        this.authService.user,
        this.billingService.getBillingCreditsBundles(),
        this.billingService.getBillingCreditsUsagesForBillingAccount(this.selectedBillingAccount.teamId, this.selectedBillingAccount.id, from, to),
        this.billingService.getUserBillingAccountPermissions(this.selectedBillingAccount.teamId, this.selectedBillingAccount.id)
      ]
    )
      .pipe(takeUntil(this.ON_DESTROY))
      .subscribe(([userWithTeams, currentUser, bundles, usages, permissions]) => {
        const billingTeam = currentUser.userTeams.find(team => team.team.id === this.selectedBillingAccount.teamId);
        this.selectedTeam = {
          ...billingTeam.team,
          isUserManager: billingTeam.role === Roles.MANAGER,
        };

        this.usersWithPermissions = userWithTeams.map((user) => {
          const userPermissions = permissions.find((permission) => permission.userId === user.id);
          if (currentUser.id === user.id) {
            this.userWithPermissions = {
              id: user.id,
              fullName: `${user.firstName} ${user.lastName}`,
              canUseAccount: userPermissions != null,
              canManageBundles: userPermissions?.canManageBundles || false,
              canManageAccount: userPermissions?.canManageAccount || false
            };
          }

          return {
            id: user.id,
            fullName: `${user.firstName} ${user.lastName}`,
            canUseAccount: userPermissions != null,
            canManageBundles: userPermissions?.canManageBundles || false,
            canManageAccount: userPermissions?.canManageAccount || false,
          };
        });

        const filteredBundles = bundles.filter(bundle => bundle.billingAccountId === this.selectedBillingAccount.id);
        this.activeBundle = filteredBundles.find(bundle => bundle.status === BillingBundleStatus.ACTIVE);
        this.bundles.data = this.addIndexToElements(sortBillingAccountBundlesByExpirationDate(filteredBundles));
        this.creditUsages.data = this.addReverseIndexToElements(usages);

        this.loadingBillingAccountInfo = false;
        this.loadingCreditUsages = false;
      });
  }

  private loadPersonalBillingAccountData(): void {
    const dateNow = new Date();
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(dateNow.getFullYear() - 1);

    combineLatest([
        this.authService.user,
        this.billingService.getBillingCreditsBundles(),
        this.billingService.getBillingCreditsUsagesForUser(oneYearAgo, dateNow),
      ]
    )
      .pipe(takeUntil(this.ON_DESTROY))
      .subscribe(([currentUser, bundles, usages]) => {
        this.userWithPermissions = {
          id: currentUser.id,
          canManageBundles: true,
          canManageAccount: true,
          canUseAccount: true,
          fullName: currentUser.firstName + ' ' + currentUser.lastName
        };
        const userBundles = bundles.filter(bundle => bundle.userId === currentUser.id);
        this.activeBundle = userBundles.find(bundle => bundle.status === BillingBundleStatus.ACTIVE);

        this.bundles.data = this.addIndexToElements(sortBillingAccountBundlesByExpirationDate(userBundles));
        this.creditUsages.data = this.addReverseIndexToElements(usages);

        this.loadingBillingAccountInfo = false;
      });
  }

  public openAddCreditsToBillingAccountModal() {
    this.modal.open(this.addCreditsModal, {
      size: 'md',
      centered: true,
      beforeDismiss: () => {
        return true;
      }
    });
  }

  public openEditBillingAccountModal() {
    this.billingAccountForm.setValue({
      name: this.selectedBillingAccount.name,
      description: this.selectedBillingAccount.description,
      emailAddress: this.selectedBillingAccount.emailAddress,
      isDefault: this.selectedBillingAccount.isDefault
    });

    this.modal.open(this.billingAccountSettingsModal, {
      size: 'md',
      centered: true,
      beforeDismiss: () => {
        return true;
      }
    });
  }

  public saveBillingAccount(): void {
    const controls = this.billingAccountForm.controls;
    const billingAccountUpdatePayload: UpdateBillingAccount = {
      name: controls.name.value,
      emailAddress: controls.emailAddress.value,
      description: controls.description.value,
      isDefault: controls.isDefault.value,
    };
    this.savingBillingAccount = true;

    this.billingService.updateBillingAccount(this.selectedTeam.id, this.selectedBillingAccount.id, billingAccountUpdatePayload)
      .subscribe(
        () => {
          this.close();
          this.savingBillingAccount = false;
        },
        () => {
          this.savingBillingAccount = false;
        }
      );
  }

  public openPermissionsModal(): void {
    const modal = this.modal.open(this.billingPermissionModal, {
      size: 'lg',
      centered: true,
    });
  }

  public saveBillingAccountPermissions(): void {
    const permissions = this.usersWithPermissions
      .filter((permissions) => permissions.canUseAccount || permissions.canManageBundles || permissions.canManageAccount)
      .map((userPermissions) => {
        return {
          userId: userPermissions.id,
          canManageBundles: userPermissions.canManageBundles,
          canManageAccount: userPermissions.canManageAccount,
        };
      });

    const value = {permissions} as UpdateBillingAccountPermissionsInput;
    this.savingPermissions = true;
    this.billingService.updateBillingAccountPermissions(this.selectedBillingAccount.teamId, this.selectedBillingAccount.id, value).subscribe((newPermissions) => {
      this.savingPermissions = false;
      this.close();
    }, () => {
      this.savingPermissions = false;
    });
  }

  public openDeleteBillingAccountConfirmationModal() {
    this.modal.open(this.deleteBillingAccountConfirmationModal, {
      size: 'md',
      centered: true,
    });
  }

  public deleteBillingAccount(): void {
    this.deletingBillingAccount = true;
    this.billingService.deleteBillingAccount(this.selectedTeam.id, this.selectedBillingAccount.id)
      .subscribe(
        () => {
          this.close();
          this.deletingBillingAccount = false;
        },
        () => {
          this.deletingBillingAccount = false;
        }
      );
  }

  public activateBundle(bundle: BillingCreditBundleWithMetadata): void {
    this.activatingBundle = true;
    this.activeBundle = bundle;

    const activateBundleObservable = bundle.belongsToPersonalAccount
      ? this.billingService.activateUserBillingCreditsBundle(bundle.id)
      : this.billingService.activateTeamBillingCreditsBundle(bundle.teamId, bundle.billingAccountId, bundle.id);


    activateBundleObservable
      .pipe(
        switchMap(() => this.billingService.getBillingCreditsBundles(true)),
      )
      .subscribe({
      next: () => this.activatingBundle = false,
      error: () => this.activatingBundle = false,
    });
  }

  public close(): void {
    this.modal.dismissAll();
  }

  public selectBundleToPurchase(bundleType: BillingProductPackageType) {
    if (this.redirectingToStripe) {
      return;
    }

    this.selectedBundle = bundleType;
  }

  public openCheckoutSession(): void {
    this.redirectingToStripe = true;
    this.billingService.billingCheckoutSession(this.selectedBillingAccount, this.selectedBundle).subscribe((session) => {
      window.location.href = session.url;
      this.close();
    });
  }

  public openCustomerPortalSessionForAccount(): void {
    this.isCreatingCustomerSession = true;

    this.billingService.createBillingCustomerSessionUrl(this.selectedBillingAccount).subscribe((session) => {
      this.isCreatingCustomerSession = false;
      window.open(session.url, "_blank");
    });
  }

  public get billingAccountFormHasError(): boolean {
    const allFieldsTouched =
      this.billingAccountForm.controls.emailAddress.touched &&
      this.billingAccountForm.controls.name.touched &&
      this.billingAccountForm.controls.description.touched;

    return allFieldsTouched && this.billingAccountForm.invalid;
  }

  public onCreditUsageDateSelection(date: NgbDate): void {
		if (!this.creditUsagesFromDate && !this.creditUsagesToDate) {
			this.creditUsagesFromDate = date;
		} else if (this.creditUsagesFromDate && !this.creditUsagesToDate && date.after(this.creditUsagesFromDate)) {
			this.creditUsagesToDate = date;
      this.loadBillingAccountUsages();
		} else {
			this.creditUsagesToDate = null;
			this.creditUsagesFromDate = date;
		}
	}

	public isHovered(date: NgbDate): boolean {
		return (
			this.creditUsagesFromDate && !this.creditUsagesToDate && this.hoveredDate && date.after(this.creditUsagesFromDate) && date.before(this.hoveredDate)
		);
	}

	public isInside(date: NgbDate) {
		return this.creditUsagesToDate && date.after(this.creditUsagesFromDate) && date.before(this.creditUsagesToDate);
	}

	public isRange(date: NgbDate): boolean {
		return (
			date.equals(this.creditUsagesFromDate) ||
			(this.creditUsagesToDate && date.equals(this.creditUsagesToDate)) ||
			this.isInside(date) ||
			this.isHovered(date)
		);
	}

	public validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
		const parsed = this.dateFormatter.parse(input);
		return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
	}

  private setDefaultDateRangeForUsages(): void {
    const today = new Date();
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(today.getFullYear() - 1);

    this.creditUsagesFromDate = new NgbDate(
      oneYearAgo.getFullYear(),
      oneYearAgo.getMonth() + 1,
      oneYearAgo.getDate()
    );

    this.creditUsagesToDate = new NgbDate(
      today.getFullYear(),
      today.getMonth() + 1,
      today.getDate()
    );
  }

  private getDateRangesForUsages(): DateRange {
    const from = new Date(this.creditUsagesFromDate.year, this.creditUsagesFromDate.month - 1, this.creditUsagesFromDate.day);
    const to = this.creditUsagesToDate ? new Date(this.creditUsagesToDate.year, this.creditUsagesToDate.month - 1, this.creditUsagesToDate.day) : new Date();

    return { from, to };
  }

  private loadBillingAccountUsages(): void {
    const { from, to } = this.getDateRangesForUsages();
    this.loadingCreditUsages = true;
    this.billingService.getBillingCreditsUsagesForBillingAccount(this.selectedBillingAccount.teamId, this.selectedBillingAccount.id, from, to)
      .subscribe((usages) => {
        this.creditUsages.data = this.addReverseIndexToElements(usages);
        this.loadingCreditUsages = false;
      });
  }

  private addIndexToElements(elements: any[]): any[] {
    return elements.map((element, index) => {
      return {
        index: index + 1,
        ...element
      };
    });
  }

  private addReverseIndexToElements(elements: any[]): any[] {
    return elements.map((element, index) => {
      return {
        index: elements.length - index,
        ...element
      };
    });
  }
}
